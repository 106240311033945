export const logoData = [
  {
    id: 1,
    image: "/assets/client-new/Anota.png",
    width: 461,
    height: 109,
  },
  {
    id: 2,
    image: "/assets/client-new/Athabasca University.png",
    width: 185,
    height: 56,
  },
  {
    id: 3,
    image: "/assets/client-new/BMJ.png",
    width: 110,
    height: 56,
  },
  {
    id: 4,
    image: "/assets/client-new/ByteMotion1.png",
    width: 236,
    height: 65,
  },
  {
    id: 5,
    image: "/assets/client-new/Continusys1.png",
    width: 1041,
    height: 203,
  },
  {
    id: 6,
    image: "/assets/client-new/eKool.png",
    width: 431,
    height: 138,
  },
  {
    id: 7,
    image: "/assets/client-new/Football5s1.png",
    width: 2480,
    height: 2894,
  },
  {
    id: 8,
    image: "/assets/client-new/Foxchain.png",
    width: 191,
    height: 188,
  },
  {
    id: 9,
    image: "/assets/client-new/Jendamark.png",
    width: 380,
    height: 133,
  },
  {
    id: 10,
    image: "/assets/client-new/Just Play.png",
    width: 435,
    height: 116,
  },
  {
    id: 11,
    image: "/assets/client-new/knowledge-academy-final.svg",
    width: 369,
    height: 40,
  },
  {
    id: 12,
    image: "/assets/client-new/Logically1.png",
    width: 158,
    height: 37,
  },
  {
    id: 13,
    image: "/assets/client-new/Odin Education.png",
    width: 2611,
    height: 753,
  },
  {
    id: 14,
    image: "/assets/client-new/Prime1.png",
    width: 1545,
    height: 513,
  },
  {
    id: 15,
    image: "/assets/client-new/scandlearn1.png",
    width: 679,
    height: 89,
  },
  {
    id: 16,
    image: "/assets/client-new/shoot-the-frame.svg",
    width: 1280,
    height: 177,
  },
  {
    id: 17,
    image: "/assets/client-new/SNO.svg",
    width: 300,
    height: 144,
  },
  {
    id: 18,
    image: "/assets/client-new/Tech Mahindra.png",
    width: 185,
    height: 56,
  },
  {
    id: 19,
    image: "/assets/client-new/OPIA1.png",
    width: 1041,
    height: 203,
  },
];
